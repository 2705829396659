import { ProductCategorie } from './ProductCategorie';
import { CheeseTypeTreatmentRecipe } from './CheeseTreatment';
import Helper from './Helper';
import { IngredientCategorie } from './IngredientCategorie';
export {
    MarkType,
    CheeseType,
    Product,
    CheeseCoating,
    WheyType,
    Ingredient,
    FailureCheese
};

class Product {
    /**
     * Assign the data to a new instance of the Product
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.isDeleted = data.deleted ?? false;
        this.isChanged = false;
        this.attcount = data.attachments;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            productID: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            naam: {
                value: data.internalname ?? data.internalName ?? data.description,
                writable: true,
                enumerable: true
            },
            internID: {
                value: data.internalid ?? data.internalID,
                writable: true,
                enumerable: true
            },
            externID: {
                value: data.externalid ?? data.externalID,
                writable: true,
                enumerable: true
            },
            externNaam: {
                value: data.externalname,
                writable: true,
                enumerable: true
            },
            opmerking: {
                value: data.remark,
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: data.perUnitID,
                writable: true,
                enumerable: true
            },
            productType: {
                value: data.productType ?? 0,
                writable: true,
                enumerable: true
            },
            // handig, niet vanuit de api maar invulbaar bij doorgeven
            prodcat: {
                type: ProductCategorie,
                value: data.prodcat ?? data.categorie ?? data.ptc_id,
                writable: true,
                enumerable: true
            },
            ingredientcat: {
                type:IngredientCategorie,
                value: data.ingrcat ?? data.igc_id,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        } else {
            if (this.naam.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('com_naam', { ns: 'common' })
                    })
                );
            }
        }
        return warnText;
    }

    SetForSave() {
        const ProductModel = {
            ID: this.productID,
            Updated: this.gewijzigd,
            InternalID: this.internID,
            InternalName: this.naam,
            Remark: this.opmerking,
            PerUnitID: this.eenheid,
            prodcat: this.prodcat,
            productType: this.productType,
            ExternalID: this.externID
            //todo
            //CamouflageText: this.opmerking,
            //EanCode: this.opmerking
        };
        return ProductModel;
    }

    SetForExternalLink() {
        const LinkProductModel = {
            ID: this.productID,
            Updated: this.gewijzigd,
            ExternalID: this.externID
        };
        return LinkProductModel;
    }
}

class CheeseType extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
        Object.defineProperties(this, {
            vetklasse: {
                value: data.fatclass ?? data.fatClass,
                writable: true,
                enumerable: true
            },
            vorm: {
                value: data.shape,
                writable: true,
                enumerable: true
            },
            vormID: {
                value: data.shapeID,
                writable: true,
                enumerable: true
            },
            merkID: {
                value: data.markTypeProductID,
                writable: true,
                enumerable: true
            },
            melkID: {
                value: data.milktypeIgtID,
                writable: true,
                enumerable: true
            },
            baseCTID: {
                value: data.baseProductID,
                writable: true,
                enumerable: true
            },
            // lijsten
            behandelRecepten: {
                value: data.treatmentRecipes
                    ? data.treatmentRecipes.map(
                          (x) => new CheeseTypeTreatmentRecipe(x)
                      )
                    : [],
                writable: true,
                enumerable: true
            }
        });
    }

    SetForSave() {
        // Product.prototype.SetForSave.call(this);
        const treatmentRecipes = [];
        for (let i = 0; i < this.behandelRecepten.length; i++) {
            const obj = this.behandelRecepten[i].SetForUpdate();
            treatmentRecipes.push(obj);
        }

        const CheeseTypeModel = {
            prodcat: this.prodcat,
            ID: this.productID,
            Updated: this.gewijzigd,
            InternalID: this.internID,
            InternalName: this.naam,
            ExternalID: this.externID,
            Remark: this.opmerking,
            PerUnitID: this.eenheid,
            MarkTypeProductID: this.merkID,
            ShapeID: this.vormID,
            MilktypeIgtID: this.melkID,
            FatClass: this.vetklasse,
            BaseProductID: this.baseCTID,
            treatmentRecipes: treatmentRecipes
        };
        return CheeseTypeModel;
    }
}

class MarkType extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
        Object.defineProperties(this, {
            vetklasse: {
                type: String,
                value: data.fatclass ?? data.fatClass,
                writable: true,
                enumerable: true
            },
            nummer: {
                type: Number,
                value: data.number,
                writable: true,
                enumerable: true
            },
            letter: {
                type: String,
                value: data.letter,
                writable: true,
                enumerable: true
            }
        });
        this.laatsteMerk = this.nummer
            ? Helper.stringformat('{0} {1}', this.letter, this.nummer)
            : '-';
    }

    SetForSave() {
        // Product.prototype.SetForSave.call(this);
        const CheeseMarkTypeModel = {
            prodcat: this.prodcat,
            ID: this.productID,
            Updated: this.gewijzigd,
            InternalID: this.internID,
            InternalName: this.naam,
            ExternalID: this.externID,
            Remark: this.opmerking,
            PerUnitID: this.eenheid,
            Fatclass: this.vetklasse,
            LastUsedLetter: this.letter,
            LastUsedNumber: this.nummer
        };
        return CheeseMarkTypeModel;
    }
}

class CheeseCoating extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
    }

    SetForSave() {
        return Product.prototype.SetForSave.call(this);
    }
}

class WheyType extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
    }

    SetForSave() {
        return Product.prototype.SetForSave.call(this);
    }
}

class FailureCheese extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
    }

    SetForSave() {
        return Product.prototype.SetForSave.call(this);
    }
}

class Ingredient extends Product {
    constructor(data) {
        data = data ?? {};
        super(data);
        this.ingredientCatID = data.prodcat ? data.prodcat.id : -1;
        if (this.ingredientCatID === -1) {
            this.ingredientCatID = data.igCategoryID;
        }
        Object.defineProperties(this, {
            groepNaam: {
                value: data.group,
                writable: true,
                enumerable: true
            },
            groepID: {
                value: data.igtId,
                writable: true,
                enumerable: true
            },
            zuurselSubType: {
                value: data.starterSubtype ?? data.isstartersubtype,
                writable: true,
                enumerable: true
            }
        });
    }

    SetForSave() {
        const IngredientModel = {
            prodcat: this.prodcat,
            ID: this.productID,
            Updated: this.gewijzigd,
            InternalID: this.internID,
            InternalName: this.naam,
            Remark: this.opmerking,
            PerUnitID: this.eenheid,
            productType: this.productType,
            ExternalID: this.externID,
            //todo
            //CamouflageText: this.opmerking,
            //EanCode: this.opmerking

            IgCategoryID: this.prodcat.id,
            IgtId: this.groepID,
            StarterSubtype: this.zuurselSubType
            // WaterAmount: this.groepID,
            // WaterTemp: this.groepID,
            // MinimumStock: this.groepID,
            // SpecIngredients: this.groepID,
            // AllowSubtypes: this.groepID,
            // ProdFactHandling: this.groepID,
        };
        return IngredientModel;
    }
}

