<template>
    <b-container fluid style="margin-top: 60px">
        <h1 @click="showBerichten()">
            {{ $t('com_production', { ns: 'common' }) }}
        </h1>
        <b-row>
            <b-col sm="8">
                <period-selector
                    id="selector-production"
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    v-bind:showBakgroepOption="true"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="2">
                <b-row v-if="!isDagInfo">
                    <b-col sm="11">
                        <b-button @click="loadData()" class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="arrow-down"
                                size="1x"
                            />&nbsp;{{ $t('com_show', { ns: 'common' }) }}
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
                <b-row v-else>
                    <b-col sm="11">
                        <b-button @click="loadData()" class="bgc_aux_normal"
                            ><font-awesome-icon icon="sync" size="1x" />&nbsp;{{
                                $t('com_refresh', { ns: 'common' })
                            }}
                        </b-button>
                        <plan-batches-button
                            class="float-right"
                            v-if="allowProductionOrders"
                            @show="showBatchesToPlan"
                        />
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="2">
                <b-row class="float-right">
                    <b-col sm="11">
                        <production-timer
                            v-if="checkFeature('conf_storeAskTimerProduction')"
                    /></b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                                <b-dropdown
                                    id="dropdown-form"
                                    :disabled="!isDagInfo"
                                    :text="
                                        $t('com_newx', {
                                            ns: 'common',
                                            val: $t('prod_bak', {
                                                ns: 'production'
                                            })
                                        })
                                    "
                                    ref="dropdown"
                                    size="sm"
                                >
                                    <b-dropdown-form style="width: 340px">
                                        <b-form-group
                                            :label="
                                                $t('com_kaassoort', {
                                                    ns: 'common'
                                                })
                                            "
                                            @submit.stop.prevent
                                        >
                                            <span>
                                                <lookup-combo
                                                    :placeholder="
                                                        $t('com_kaassoort', {
                                                            ns: 'common'
                                                        })
                                                    "
                                                    v-model="selectedProductID"
                                                    v-bind:combotype="
                                                        lctypeCheeses
                                                    "
                                                    :doCheckState="true"
                                                ></lookup-combo>
                                                <b-form-input
                                                    placeholder="strBakken"
                                                    v-model.number="
                                                        selectedAmount
                                                    "
                                                    size="sm"
                                                ></b-form-input>
                                            </span>
                                        </b-form-group>
                                        <b-button
                                            size="sm"
                                            @click="createNewItem"
                                            class="mr-1"
                                            ><font-awesome-icon
                                                icon="folder-plus"
                                                size="1x"
                                            />
                                            {{
                                                $t('prod_toevoegen', {
                                                    ns: 'production'
                                                })
                                            }}
                                        </b-button>
                                    </b-dropdown-form>
                                </b-dropdown>
                                &nbsp;
                                <instant-milk-stock
                                    v-if="displayMilkButton"
                                    size="sm"
                                    v-bind:productIn="-1"
                                    v-bind:productListIn="milkList"
                                    v-bind:disabled="!isDagInfo"
                                />
                                &nbsp;

                                <b-form-select
                                    v-if="displayStarterTypeButton"
                                    v-model="starterID"
                                    :options="starterList"
                                >
                                    <template #first>
                                        <b-form-select-option value="-1"
                                            >--
                                            {{
                                                $t('com_selecteer', {
                                                    ns: 'common'
                                                })
                                            }}
                                            --</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-dropdown
                        v-if="checkRight('BatchCreate')"
                        id="dropdown-form"
                        :disabled="!isDagInfo"
                        :text="
                            $t('com_newx', {
                                ns: 'common',
                                val: $t('prod_bak', { ns: 'production' })
                            })
                        "
                        ref="dropdown"
                        size="sm"
                    >
                        <b-dropdown-form style="width: 340px">
                            <b-form-group
                                :label="$t('com_kaassoort', { ns: 'common' })"
                                @submit.stop.prevent
                            >
                                <span>
                                    <lookup-combo
                                        :placeholder="
                                            $t('com_kaassoort', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="selectedProductID"
                                        v-bind:combotype="lctypeCheeses"
                                        :doCheckState="true"
                                    ></lookup-combo>
                                    <lookup-combo
                                        :placeholder="
                                            $t('prod_vorm', {
                                                ns: 'production'
                                            })
                                        "
                                        v-model="selectedShapeID"
                                        :disabled="setDisabledShape"
                                        v-bind:combotype="lctypeShapes"
                                    ></lookup-combo>
                                    <b-form-input
                                        placeholder="strBakken"
                                        v-model.number="selectedAmount"
                                        size="sm"
                                    ></b-form-input>
                                    <b-form-input
                                        :placeholder="
                                            $t('prod_verantw', {
                                                ns: 'production'
                                            })
                                        "
                                        v-model="selectedPerson"
                                        size="sm"
                                    ></b-form-input>
                                </span>
                            </b-form-group>
                            <b-button
                                size="sm"
                                @click="createNewItem"
                                class="mr-1"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                                {{ $t('prod_toevoegen', { ns: 'production' }) }}
                            </b-button>
                        </b-dropdown-form>
                    </b-dropdown>
                    &nbsp;
                    <instant-milk-stock
                        v-if="displayMilkButton && checkRight('StockIn')"
                        size="sm"
                        v-bind:productIn="-1"
                        v-bind:productListIn="milkList"
                        v-bind:disabled="!isDagInfo"
                    />
                    &nbsp;
                    <b-dropdown
                        v-if="
                            displayStarterTypeButton &&
                            checkRight('BatchCreate')
                        "
                        :disabled="!isDagInfo"
                        :text="starterNaam"
                        ref="dropdownStarter"
                        size="sm"
                    >
                        <b-dropdown-form style="width: 340px">
                            <b-form-group
                                :label="
                                    $t('prod_startergroup', {
                                        ns: 'production'
                                    })
                                "
                                @submit.stop.prevent
                            >
                                <span>
                                    <b-form-select
                                        v-model="starterID"
                                        :options="starterList"
                                    >
                                        <template #first>
                                            <b-form-select-option value="-1"
                                                >--
                                                {{
                                                    $t('com_selecteer', {
                                                        ns: 'common'
                                                    })
                                                }}
                                                --</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                </span>
                            </b-form-group>
                            <b-button size="sm" @click="setSubType" class="mr-1"
                                ><font-awesome-icon icon="sync" size="1x" />
                                {{ $t('com_opslaan', { ns: 'common' }) }}
                            </b-button>
                        </b-dropdown-form>
                    </b-dropdown>
                </div>
                <b-table
                    :key="aangeraakt_teller"
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonDetail"
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                v-if="allowEdit && checkRight('BatchCreate')"
                                size="sm"
                                @click="createNewItem(row.item)"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                            </b-button>
                            <b-button
                                v-if="allowEdit && checkRight('BatchDelete')"
                                size="sm"
                                @click="verwijderItem(row.item)"
                                class="bgc_aux_alarm"
                                ><font-awesome-icon
                                    icon="trash-alt"
                                    size="1x"
                                />
                            </b-button>
                            <b-button
                                size="sm"
                                @click="toonDetail(row.item)"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                            <prod-drop-down-builder
                                size="sm"
                                v-if="checkRight('BatchUpdate')"
                                v-bind:bakID="row.item.id"
                                v-bind:isLastBatch="isLaatsteBak(row.item)"
                                v-bind:editAllowed="allowEdit"
                                @production_refresh="loadData()"
                            >
                            </prod-drop-down-builder>
                        </div>
                    </template>

                    <template v-slot:cell(ing_toegevoegd)="row">
                        <b-badge
                            v-if="row.item.ing_toegevoegd === 0"
                            variant="success"
                        >
                            ok
                        </b-badge>
                        <b-badge
                            v-else-if="row.item.ing_toegevoegd < 0"
                            variant="success"
                        >
                            ok +
                        </b-badge>
                        <b-badge v-else variant="warning">
                            {{ row.item.ing_toegevoegd }}
                        </b-badge>
                    </template>

                    <template v-slot:row-details>
                        <recipe-card
                            v-model="selectedRecipe"
                            v-bind:editAllowed="false"
                            v-bind:closedRecipesList="null"
                            v-bind:activityList="activiteitenList"
                        >
                        </recipe-card>
                    </template>
                </b-table>
                <div v-if="displayList.length == 0">
                    {{ strNoData }}
                </div>
            </b-col>
        </b-row>
        <plan-batches-form
            v-if="planBatchesVisible"
            v-model="planBatchesVisible"
            :lijst="bakkenlijst"
            :refresh="refresh"
            @hide="reset"
            @addBatch="addBatch"
        />
    </b-container>
</template>

<script>
import ProdDropDownBuilder from './ProdDropDownBuilder.vue';
import { LookupCombo } from '../components';
import RecipeCard from '../recipe/RecipeCard';
import PeriodSelector from '../components/PeriodSelector.vue';
import InstantMilkStock from './InstantMilkStock';
import ProductionTimer from './ProductionTimer';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { PlanBatchesButton, PlanBatchesForm } from '../productionorder';
import { loadItem, loadList } from '../../models/DataHelper';
import { Permission } from '../../models/Permissions';
import { Feature } from '../../models/Features';
import logMessage from '../errors/foutlog';

export default {
    name: 'ProductionBase',
    data: function () {
        return {
            lctypeCheeses: window.constants.LCCHEESE,
            lctypeShapes: window.constants.LCSHAPES,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            van: null,
            tot: null,
            currentItem: null,
            detailsVisible: false,
            gesloten: false,
            selectedProductID: null,
            selectedShapeID: null,
            selectedAmount: 1,
            selectedPerson: '',
            aangeraakt_teller: 1,
            selectDays: null,
            periodeDagen: 1,
            editDagen: -1,
            showMilkButton: false,
            showStarterButton: false,
            showMeldingen: true,
            planBatchesVisible: false,
            subtypeFilter: null,
            starterID: -1,
            forceRefresh: false,
            refresh: 0,
            starterNaam: this.$t('prod_onbekend', {
                ns: 'production'
            }),
            zuurselLijst: [],
            activiteitenList: [],
            bakkenlijst: [],
            displayFilterCount: 10,
            operator: null,
            onbekend: this.$t('prod_onbekend', {
                ns: 'production'
            })
        };
    },
    components: {
        LookupCombo,
        ProdDropDownBuilder,
        RecipeCard,
        PeriodSelector,
        InstantMilkStock,
        ProductionTimer,
        PlanBatchesButton,
        PlanBatchesForm
    },
    computed: {
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        strBakken() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('prod_bakken', { ns: 'production' }).toLowerCase()
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'productieDatum',
                    label: this.$t('com_datum', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                { key: 'bakno', label: 'Bak#', sortable: true, visible: true },
                {
                    key: 'bakgroepInternID',
                    label: 'BG#',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'kaasnaam',
                    label: this.$t('com_kaassoort', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'ing_toegevoegd',
                    label: this.$t('com_klaar', { ns: 'common' }),
                    visible: this.isDagInfo
                },
                {
                    key: 'aantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'inpekeltijd',
                    label: this.$t('prod_inpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'kooilaag',
                    label: this.$t('prod_kooi', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'urenInPekel',
                    label: this.$t('prod_uurinpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'uitpekel',
                    label: this.$t('prod_uitpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            const lijst = this.isDagInfo
                ? this.$store.getters['production/getBatchInfoLijst']
                : this.$store.getters['production/getBatchInfoHistLijst'];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        allowProductionOrders() {
            return this.isDagInfo && this.checkFeature('conf_useProductionOrders');
        },
        isDagInfo() {
            return this.van == this.tot;
        },
        displayMilkButton() {
            return this.showMilkButton;
        },
        milkList() {
            return this.$store.getters['production/getMilkList'];
        },
        displayStarterTypeButton() {
            return this.showStarterButton;
        },
        starterList() {
            return this.zuurselLijst;
        },
        selectedRecipe() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            //return this.$store.getters['recipe/getRecipe'];
            return this.currentItem;
        },
        selectedBatch() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            return this.$store.getters['production/getSelectedBatch'];
        },
        setDisabledShape() {
            return this.disableMultipleProductShapes;
        },
        allowEdit() {
            const o = DateHelper.determineVanTot(new Date(), this.editDagen);
            const magNog = o.van < this.van;
            return this.isDagInfo && magNog;
        },
        disableMultipleProductShapes() {
            const disableMultipleProductShapes = this.checkFeature(
                'conf_disableMultipleProductShapes'
            );
            return disableMultipleProductShapes;
        }
    },
    methods: {
        reset() {
            this.planBatchesVisible = false;
        },
        async getDetailItem(item) {
            this.activiteitenList = await loadList(
                'production/getBatchActivityList',
                'production/loadBatchActivityLog',
                { batchid: item.id },
                true,
                this.$t
            );
            return await loadItem(
                'production/loadBatch',
                {
                    batchID: item.id
                },
                this.$t
            );
        },
        setSelectedStarter() {
            const prod = this.$store.getters['production/getSelectedProdrun'];
            this.starterID = prod.zuurselTypeID;
            this.starterNaam = prod.zuurselTypeNaam ?? this.onbekend;
        },
        async setSubType() {
            const prod = this.$store.getters['production/getSelectedProdrun'];
            const selected = this.starterID ?? -1;
            const item = this.zuurselLijst.find((x) => x.value == selected);
            this.starterNaam = item ? item.text : this.onbekend;
            const selectionData = {
                prodrunID: prod.id,
                starterSubtypeProductID: selected == -1 ? null : selected
            };
            this.$refs.dropdownStarter.hide(true);
            const succes = await loadItem(
                'production/addStarterSubType',
                selectionData,
                this.$t
            );
            if (succes) {
                this.loadData();
            }
        },
        checkIngredientsAdded(item) {
            return item.recingredients == item.batchingredients;
        },
        async toonDetail(row) {
            const refreshedItem = await this.getDetailItem(row);
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                this.currentItem = await loadItem(
                    'recipe/loadRecipeComplete',
                    { recipeid: refreshedItem.receptID },
                    this.$t
                );
                this.aangeraakt_teller += 1;
                this.$set(row, '_showDetails', true);
            }
        },
        async createNewItem(item) {
            if (item) {
                const list =
                    this.$store.getters['components/getCheesesComboLijst'];
                const tempSelect = list.find((x) => x.text == item.kaasnaam);
                if (tempSelect) this.selectedProductID = tempSelect.value;
                this.selectedPerson = this.selectedPerson
                    ? this.selectedPerson
                    : item.verantwoordelijke;
            }
            if (
                this.selectedProductID != undefined &&
                this.selectedProductID > -1
            ) {
                let gaDoor =
                    this.selectedAmount > window.constants.WARN_BAKKEN
                        ? false
                        : true;
                if (!gaDoor) {
                    gaDoor = await this.$root.showConfirmationBox(
                        this.$t('prod_teveelbakken', {
                            ns: 'production',
                            val: this.selectedAmount
                        })
                    );
                }
                if (gaDoor) {
                    const selectionData = {
                        bookdate: DateHelper.localDateToUTC(this.van),
                        prodLineID: window.constants.DEFPRODLINE,
                        productID: this.selectedProductID,
                        batches: this.selectedAmount,
                        responsible: this.selectedPerson,
                        shapeID: this.selectedShapeID
                    };
                    this.$refs.dropdown.hide(true);
                    await loadItem(
                        'production/addBatches',
                        selectionData,
                        this.$t
                    );
                    this.selectedAmount = 1;
                    this.loadData();
                }
            }
        },
        async addBatch(order) {
            if (order) {
                this.selectedAmount = 1;
                const selectionData = {
                    bookdate: DateHelper.localDateToUTC(this.van),
                    prodLineID: window.constants.DEFPRODLINE,
                    productID: 0,
                    batches: this.selectedAmount,
                    responsible: this.selectedPerson,
                    shapeID: null, // default shape
                    orderLineID: order.id
                };
                await loadItem('production/addBatches', selectionData, this.$t);
                this.loadData();
                this.refresh +=1;
            }
        },
        async verwijderItem(item) {
            const refreshedItem = await this.getDetailItem(item);
            if (refreshedItem.inpekeltijd == undefined) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('com_delete', {
                        ns: 'common',
                        val: this.$t('prod_bak', { ns: 'production' })
                    }) +
                        ' : ' +
                        item.bakno
                );
                if (doClose) {
                    const selectionData = {
                        batchid: refreshedItem.id,
                        LastUpdated: refreshedItem.gewijzigd
                    };
                    await loadItem(
                        'production/deleteBatch',
                        selectionData,
                        this.$t
                    );
                }
                this.loadData();
            } else {
                this.$root.showMessageBox(
                    this.$t('prod_nodelete', {
                        ns: 'production'
                    })
                );
            }
        },
        async setShowStarterSelect() {
            this.showStarterButton = false;
            if (this.isDagInfo) {
                const selectionData = {
                    bookdate: DateHelper.localDateToUTC(this.van)
                };
                const lijst = await loadItem(
                    'production/loadStarterInfo',
                    selectionData,
                    this.$t
                );
                if (lijst.length < 1) return;
                this.subtypeFilter = lijst[0];
                const lijst2 = await loadItem(
                    'product/loadStarterSubTypeInfo',
                    null,
                    this.$t
                );
                this.setSelectedStarter();
                lijst2.filter((x) => x.zuurselSubType == this.subtypeFilter);
                this.zuurselLijst = lijst2.map((x) => ({
                    value: x.id,
                    text: x.naam
                }));
                this.showStarterButton = true;
            }
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            if (this.isDagInfo) this.loadData();
        },
        isLaatsteBak(item) {
            let result = false;
            if (this.isDagInfo) {
                const laatsteBak = Math.max(
                    ...this.displayList.map((o) => o.bakno)
                );
                result = item.bakno == laatsteBak;
            }
            return result;
        },
        loadData() {
            if (this.isDagInfo) {
                const selectionData = {
                    bookdate: DateHelper.localDateToUTC(this.van)
                };
                loadItem('production/loadBatchInfo', selectionData, this.$t);
                loadItem('production/loadMilkInfo', selectionData, this.$t);
                loadItem('components/loadUnitsComboLijst', null, this.$t);
                loadItem('production/loadProdrun', selectionData, this.$t);
                this.setShowStarterSelect();
            } else {
                const selectionData = {
                    from: DateHelper.localDateToUTC(this.van),
                    until: DateHelper.localDateToUTC(this.tot)
                };
                loadItem(
                    'production/loadBatchInfoOverPeriod',
                    selectionData,
                    this.$t
                );
            }
            this.aangeraakt_teller += 1;
        },
        showBerichten() {
            const meldingen =
                this.$store.getters['masterdata/getBerichtenLijst'];
            if (meldingen.length > 0 && this.showMeldingen) {
                Helper.showBerichten(meldingen, this.$t, this.$bvToast);
                //this.showMeldingen = true;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        showBatchesToPlan(bakkenlijst) {
            this.bakkenlijst = bakkenlijst;
            this.planBatchesVisible = true;
        }
    },
    mounted: function () {
        this.loadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find((x) => x.text == window.constants.APPCONST_EDITDAYS)
            : null;
        this.editDagen = con ? con.value : 30;
        const func = this.$t;
        // ToDo: haal altijd alles op, bij grote lijsten is dit niet handig!!
        loadItem('product/loadIngredientCategories', null, func)
            .then(async (ingrcatArray) => {
                await Promise.all(
                    ingrcatArray.map(async (ingrcat) => {
                        ingrcat.lijst = await loadItem(
                            'product/loadIngredientInfo',
                            { category: ingrcat.naam },
                            func
                        );
                    })
                );
            })
            .then((this.showMilkButton = true));
        const loggedIn = this.$store.getters['account/hasValidatedJWTToken'];
        if (!loggedIn) this.$router.push({ name: 'empty' });
        this.showBerichten();
    }
};
</script>

<style scoped></style>
