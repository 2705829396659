import Vue from 'vue';
import Vuex from 'vuex';

import accountModule from './features/account/store';
import gebruikerModule from './features/gebruiker/store';
import productModule from './features/product/store';
import recipeModule from './features/recipe/store';
import stockModule from './features/stock/store';
import deliveryModule from './features/delivery/store';
import rapportageModule from './features/rapportage/store';
import componentsModule from './features/components/store';
import productionModule from './features/production/store';
import outbrineModule from './features/outbrine/store';
import masterdataModule from './features/masterdata/store';
import maintenanceModule from './features/maintenance/store';
import treatmentModule from './features/treatment/store';
import recurrencyModule from './features/recurrency/store';
import tienplusModule from './features/10plus/store';
import externModule from './features/externcomm/store';
import poModule from './features/productionorder/store';

import analyticsModule from './features/analytics/store';

Vue.use(Vuex);

const Store = new Vuex.Store({
    modules: {
        account: accountModule,
        gebruiker: gebruikerModule,
        product: productModule,
        recipe: recipeModule,
        stock: stockModule,
        rapportage: rapportageModule,
        components: componentsModule,
        production: productionModule,
        outbrine: outbrineModule,
        masterdata: masterdataModule,
        maintenance: maintenanceModule,
        analytics: analyticsModule,
        delivery: deliveryModule,
        treatment: treatmentModule,
        recurrency: recurrencyModule,
        tienplus: tienplusModule,
        extern: externModule,
        productionorder: poModule
    },
    state: {
        flash: null
    },
    getters: {
        flash(state) {
            return state.flash;
        }
    },
    mutations: {
        setNotification(state, { type, message, title }) {
            state.flash = {
                type,
                title,
                message
            };
        },
        flashNotification(state) {
            state.flash = null;
        }
    }
});

export default Store;
