<template>
    <b-modal
        id="modal-planexternalbatches"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <div class="form-inline">
            <period-selector
                id="selector-productionorder"
                style="width: 85%"
                v-model="selectDays"
                v-bind:periodDays="periodeDagen"
                v-bind:forceToday="true"
                @change="setNewDates"
            />
            <b-button @click="loadData()" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-down" size="1x" />&nbsp;{{
                    $t('com_show', { ns: 'common' })
                }}
            </b-button>
        </div>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <b-table
            striped
            responsive
            :fields="visibleFields"
            :items="displayList"
            stacked="md"
            small
            show-empty
            :empty-text="$t('com_geen_data', { ns: 'common' })"
        >
            <template v-slot:cell(actions)="row">
                <b-button
                    size="sm"
                    @click="addItem(row.item)"
                    class="bgc_aux_normal"
                    ><font-awesome-icon icon="folder-plus" size="1x" />
                </b-button>
            </template>
        </b-table>
    </b-modal>
</template>

<script>
import _ from 'lodash';
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
import PeriodSelector from '../components/PeriodSelector.vue';

export default {
    name: 'PlanBatchesForm',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            selectDays: null,
            periodeDagen: 1,
            orderList: []
        };
    },
    components: {
        PeriodSelector
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        lijst: {
            type: Array,
            default: () => []
        },
        refresh: {
            type: Number,
            default: 0
        }
    },
    watch: {
        refresh() {
            this.loadData();
        }
    },
    computed: {
        formtitle() {
            return this.$t('Productieorders', { ns: 'production' });
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'pt_internalname',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'ol_requesteddate',
                    label: this.$t('prod_reqdate', { ns: 'production' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'ol_requestedamount',
                    label: this.$t('prod_requested', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'amount',
                    label: this.$t('prod_assigned', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'rest',
                    label: this.$t('prod_rest', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productions',
                    label: this.$t('prod_bakken', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const lijst = this.orderList;
            return lijst;
        },
        isDagInfo() {
            return this.van == this.tot;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        async loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot)
            };
            this.orderList = await loadItem(
                'productionorder/loadProductionOrderInfo',
                selectionData,
                this.$t
            );
        },
        hide() {
            this.$emit('hide');
        },
        addItem(item) {
            this.$emit('addBatch', item);
        },
        checkFormValidity() {
            return true;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-planexternalbatches');
            });
        }
    },
    mounted() {},
    created() {
        this.orderList = this.lijst;
    }
};
</script>

<style scoped></style>
