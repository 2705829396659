import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';

// eslint-disable-next-line no-unused-vars
import {
    CheeseCoating,
    CheeseType,
    MarkType,
    WheyType,
    Product,
    Ingredient,
    FailureCheese
} from '../../../models/Product';
import { SeasonValues } from '../../../models/Recept';
import {
    PRODCAT_ENUM,
    ProductCategorie
} from '../../../models/ProductCategorie';
import { IngredientCategorie } from '../../../models/IngredientCategorie';
import { VoorraadItem } from '../../../models/Voorraad';

const actions = {
    //#region Kaas
    async loadCheeseTypeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseTypeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new CheeseType(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaassoort', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertCheeseTypeInfoLijst', tempList);
    },
    async loadCheeseType(context, data) {
        let item = null;
        try {
            const response = await api.fetchCheeseType(data.data);
            item = new CheeseType(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaassoort', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertCheeseType', item);
        return item;
    },

    // productID
    async loadCheeseTypeIngredients(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchCheeseTypeIngredients(data.data);
            tempList = res.data.Items.map((x) => ({
                ingredientProductID: x.ig_pt_id,
                ingredientNaam: x.ingredient,
                kaasProductID: x.pt_id,
                kaasNaam: x.cheesetype
            }));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    //#endregion Kaas

    //#region Merktype
    async loadMarkTypeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseMarkTypeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new MarkType(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_marktype', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkTypeInfo', tempList);
    },
    async loadMarkType(context, data) {
        let item = null;
        try {
            const response = await api.fetchCheeseMarkType(data.data);
            item = new MarkType(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_marktype', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkType', item);
        return item;
    },
    // async loadJustMarkType(context, data) {
    //     let item = null;
    //     try {
    //         const response = await api.fetchCheeseMarkType(data);
    //         item = new MarkType(response.data);
    //     } catch (error) {
    //         item = null;
    //         showBadToast(context, 'Melding laden detail', error);
    //     }
    //     return item;
    // },
    //#endregion Merktype

    //#region Coating
    async loadCheeseCoatingInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseCoatingInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new CheeseCoating(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_coating', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertCheeseCoatingInfo', tempList);
    },
    async loadCheeseCoating(context, data) {
        let item = null;
        try {
            const response = await api.fetchCheeseCoating(data.data);
            item = new CheeseCoating(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_coating', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertCheeseCoating', item);
        return item;
    },
    //#endregion Coating

    //#region WheyType
    async loadWheyTypeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchWheyTypeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new WheyType(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_wheytype', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertWheyTypeInfo', tempList);
    },
    async loadWheyType(context, data) {
        let item = null;
        try {
            const response = await api.fetchWheyType(data.data);
            item = new WheyType(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_wheytype', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertWheyType', item);
        return item;
    },
    //#endregion WheyType

    //#region FailureCheese
    async loadFailureCheeseInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchFailureCheeseInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new FailureCheese(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_onvolwaardig', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertFailureCheeseInfo', tempList);
    },
    async loadFailureCheese(context, data) {
        let item = null;
        try {
            const response = await api.fetchFailureCheese(data.data);
            item = new FailureCheese(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_onvolwaardig', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertFailureCheese', item);
        return item;
    },
    //#endregion FailureCheese

    //#region Overige
    async loadOverigeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchOverigeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Product(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('nav_productOverige', { ns: 'nav' })
                }),
                error
            );
        }
        context.commit('insertOverigeInfo', tempList);
    },
    async loadOverige(context, data) {
        let item = null;
        try {
            const response = await api.fetchOverige(data.data);
            item = new Product(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('nav_productOverige', { ns: 'nav' })
                }),
                error
            );
        }
        context.commit('insertOverige', item);
        return item;
    },
    //#endregion Overige

    //#region Ingredient
    async loadStarterSubTypeInfo(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchStarterSubTypeInfo(data.data);
            tempList = res.data.Items.map((x) => new Ingredient(x));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_zuursellijst', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertStarterSubtypeLijst', tempList);
        return tempList;
    },

    async loadIngredientInfo(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchIngredientInfo(data.data);
            tempList = res.data.Items.map((x) => new Ingredient(x));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertIngredientInfoLijst', tempList);
        return tempList;
    },
    async loadIngredient(context, data) {
        let item = null;
        try {
            const response = await api.fetchIngredient(data.data);
            item = new Ingredient(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertIngredient', item);
        return item;
    },
    // productID
    async loadAlternativeIngredients(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchAlternativeIngredients(data.data);
            tempList = response.data.map((x) => new Ingredient(x));
        } catch (error) {
            tempList = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
        return tempList;
    },
    // productID
    async loadIngredientCheeseTypes(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchIngredientCheeseTypes(data.data);
            tempList = res.data.Items.map((x) => ({
                ingredientProductID: x.ig_pt_id,
                ingredientNaam: x.ingredient,
                kaasProductID: x.pt_id,
                kaasNaam: x.cheesetype
            }));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async loadSeasonValues(context, data) {
        let item = null;
        try {
            const response = await api.fetchSeasonValues(data.data);
            item = new SeasonValues(response.data);
            if (item.elementen.length == 0) item.fillEmpty(data.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_seasonvalues', { ns: 'production' })
                }),
                error
            );
        }
        return item;
    },
    async saveSeasonValues(context, data) {
        try {
            await api.saveSeasonValues(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_seasonvalues', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_seasonvalues', { ns: 'production' })
                }),
                error
            );
        }
    },
    // List<int> productIDs
    async saveGroupStarterIngredients(context, data) {
        let item = null;
        try {
            item = await api.saveGroupStarterIngredients(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_startergroups', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_startergroups', { ns: 'production' })
                }),
                error
            );
        }
        return item;
    },
    // int productID
    async removeStarterFromGroup(context, data) {
        let item = null;
        try {
            item = await api.saveRemoveStarterFromGroup(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_startergroups', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_startergroups', { ns: 'production' })
                }),
                error
            );
        }
        return item;
    },

    //#endregion Ingredient

    //voorraad
    async loadStockByProduct(context, data) {
        let tempList = [];
        try {
            await api.fetchStockByProduct(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new VoorraadItem(x));
                context.commit('insertVoorraadItemLijst', tempList);
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
    },

    //prodcat
    async loadProductCategories(context, data) {
        let tempList = [];
        try {
            await api.fetchProductCategories(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new ProductCategorie(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_productcats', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertProductCategoriesLijst', tempList);
    },
    async loadIngredientCategories(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchIngredientCategories(data.data);
            tempList = res.data.Items.map((x) => new IngredientCategorie(x));
        } catch (error) {
            tempList = []; // todo
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredientcat', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertIngredientCategoriesLijst', tempList);
        return tempList;
    },

    // product
    async linkProduct(context, data) {
        let res ='';
        try {
            res = await api.linkProduct(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return res;
    },    
    async getBaseUnit(context, data) {
        let id = null;
        try {
            const response = await api.fetchBaseUnitExternalProduct(data.data);
            id = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_unit', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return id;
    },    
    async saveProduct(context, data) {
        try {
            const isProcCat = data.data.prodcat.type === 'ProductCategorie';
            if (isProcCat) {
                switch (data.data.prodcat.id) {
                    case PRODCAT_ENUM.Kaas:
                        await api.saveCheeseType(data.data);
                        context.commit('insertCheeseType', data.data);
                        break;
                    case PRODCAT_ENUM.Kaasmerk:
                        await api.saveCheeseMarkType(data.data);
                        context.commit('insertMarkType', data.data);
                        break;
                    case PRODCAT_ENUM.Kaasdekmiddel:
                        await api.saveCheeseCoating(data.data);
                        context.commit('insertCheeseCoating', data.data);
                        break;
                    case PRODCAT_ENUM.Wei:
                        await api.saveWheyType(data.data);
                        context.commit('insertWheyType', data.data);
                        break;
                    case PRODCAT_ENUM.Onvolwaardig:
                        await api.saveFailureCheese(data.data);
                        context.commit('insertFailureCheese', data.data);
                        break;
                    case PRODCAT_ENUM.Overige:
                        await api.saveOverige(data.data);
                        context.commit('insertOverige', data.data);
                        break;
                    case PRODCAT_ENUM.Ingrediënt:
                    default: {
                        await api.saveProduct(data.data); // bestaat nog niet
                        break;
                    }
                }
            } else {
                await api.saveIngredient(data.data);
                context.commit('insertIngredient', data.data);
            }
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.data.prodcat.naam
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                }),
                error
            );
        }
    },
    async saveIngredient(context, data) {
        try {
            await api.saveIngredient(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.data.prodcat.naam
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
    },
    async deleteProduct(context, data) {
        try {
            const selectionData = {
                productID: data.data.item.id
            };
            await api.deleteProduct(selectionData);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.data.item.naam
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                }),
                error
            );
        }
    },
    async loadAllProducts(context, data) {
        let tempList = [];
        try {
            await api.fetchAllProducts(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Product(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_products', { ns: 'nav' })
                }),
                error
            );
        }
        context.commit('insertProductsLijst', tempList);
    },
    async loadProduct(context, data) {
        let item = null;
        try {
            const response = await api.fetchProduct(data.data);
            item = new Product(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    }
};

export default actions;

