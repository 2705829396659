export {
    OrderHeader,
    OrderInfo,
    OrderLine
};
import { RecurrencyPattern, RecurrencyModel } from './Recurrency';
import DateHelper from './DateHelper';

class OrderHeader {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.iD ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            internID: {
                type: String,
                value: data.internalid,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.status,
                writable: true,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.type,
                writable: true,
                enumerable: true
            },
            klantID: {
                type: Number,
                value: data.customerID,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            klantRef: {
                type: String,
                value: data.reference,
                writable: true,
                enumerable: true
            },
            bevestigdOp: {
                type: Date,
                value: data.confirmed ,
                writable: true,
                enumerable: true
            },
            bevestigdDoor: {
                type: String,
                value: data.confirmedBy ,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForAdd() {
        const OrderModel = {
            Confirmed: DateHelper.localDateToUTC(this.bevestigdOp),
            Confirmedby: this.bevestigdDoor,
            Remark: this.opmerking
        };
        return OrderModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

class OrderLine {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.iD ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            internID: {
                type: String,
                value: data.internalid,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.orderLineStatus,
                writable: true,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.orderLineType,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            klantRef: {
                type: String,
                value: data.reference,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: data.productName,
                writable: true,
                enumerable: true
            },
            vraagDatum: {
                type: Date,
                value: data.requesteddate ,
                writable: true,
                enumerable: true
            },
            vraagAantal: {
                type: Number,
                value: data.requestedamount ,
                writable: true,
                enumerable: true
            }
        });
    }
    SetForAdd() {
        const OrderLineModel = {
            Type: this.typeID,
            Activity: this.activteitID
        };
        return OrderLineModel;
    }
}

class OrderInfo {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.editAllowed = true;
        data = data ?? {};
        this.id = data.id,
        this.oh_externalid = data.order,
        this.ol_externalid = data.line,
        this.pt_internalname = data.product,
        this.ol_requestedamount = data.requested,
        this.ol_requesteddate = data.requesteddate,
        this.amount = data.assigned,
        this.productions = data.productions,
        this.rest = data.unassigned,
        this.externID = this.oh_externalid + '-' + this.ol_externalid
    }

}

