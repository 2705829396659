<template>
    <b-modal
        id="modal-deliverynote"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab :title="headertitle" v-bind:key="1" active>
                    <b-card-text>
                        <div>
                            <b-row>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="dn_reference"
                                                >{{
                                                    $t('com_reference', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="dn_reference"
                                                :placeholder="
                                                    $t('com_reference', {
                                                        ns: 'common'
                                                    })
                                                "
                                                v-model="localItem.referentie"
                                                :disabled="!editAllowed"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="dn_boekdate">
                                                {{
                                                    $t('prod_boekdatum', {
                                                        ns: 'production'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-datepicker
                                                id="dn_boekdate"
                                                :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit'
                                                }"
                                                v-model="datum"
                                                @input="setChanged"
                                                :disabled="!editAllowed"
                                            ></b-form-datepicker>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="dn_location">
                                                {{
                                                    $t('com_location', {
                                                        ns: 'common'
                                                    })
                                                }}</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                id="dn_location"
                                                v-model="localItem.locatie"
                                                v-bind:combotype="
                                                    lctypeLocation
                                                "
                                                :disabled="!editAllowed"
                                                @change="setChanged"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <div class="form-inline">
                                                <label
                                                    >{{
                                                        $t('com_klant', {
                                                            ns: 'common'
                                                        })
                                                    }}:</label
                                                >&nbsp; &nbsp;
                                                <b-form-checkbox
                                                    v-model="freeinput"
                                                    value="true"
                                                    unchecked-value="false"
                                                    :disabled="!editAllowed"
                                                >
                                                    <span>{{
                                                        $t('com_free', {
                                                            ns: 'common'
                                                        })
                                                    }}</span>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>
                                        <b-col sm="9" v-if="!showFreeInput">
                                            <lookup-combo
                                                v-model="localItem.klant"
                                                v-bind:combotype="lctypeCompany"
                                                :disabled="!editAllowed"
                                                @change="setChanged"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="9" v-if="showFreeInput">
                                            <b-form-textarea
                                                :placeholder="
                                                    $t('prod_vrijadres', {
                                                        ns: 'production'
                                                    })
                                                "
                                                v-model="localItem.klanttekst"
                                                :disabled="!editAllowed"
                                                @change="setChanged"
                                                rows="3"
                                                no-resize
                                            ></b-form-textarea>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="1">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="11">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>
                    <special-attachments
                        v-if="localItem.id > 0"
                        :itemId="localItem.id"
                        :attType="attachmentType"
                    />
<!--                 <b-tab
                    v-if="localItem.id > 0"
                    :title="$t('com_bijlagen', { ns: 'common' })"
                    v-bind:key="3"
                    @click="loadAttachments()"
                    lazy
                >
                    <b-card-text>
                        <file-upload
                            :attID="localItem.id"
                            :attType="attachmentType"
                            @input="loadAttachments"
                            @queue="setQueuedAmount"
                        />
                        <b-table
                            :items="attachmentLijst"
                            :fields="visibleFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                        >
                            <template v-slot:cell(actions)="row">
                                <b-button
                                    size="sm"
                                    @click="showAttachment(row.item)"
                                    class="bgc_aux_normal"
                                    ><font-awesome-icon icon="edit" size="1x" />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="verwijderAttachment(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </template>

                            <template v-slot:row-details> </template>
                        </b-table>
                    </b-card-text>
                </b-tab> -->
            </b-tabs>
        </b-card>
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('log_lines', { ns: 'logistics' })"
        >
            <div>
                <b-row>
                    <delivery-note-items
                        v-if="localItem != undefined"
                        v-bind:items="localItem.regels"
                        v-bind:geprint="!editAllowed"
                        @change="setChanged"
                    />
                </b-row>
            </div>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { DeliveryNote } from '../../models/Delivery';
import { loadItem } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';
import { LookupCombo, FileUpload, SpecialAttachments } from '../components';
import DeliveryNoteItems from './DeliveryNoteItems.vue';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { AttachmentInfo } from '@/models/PlusProduction';

export default defineComponent({
    name: 'DeliveryNoteModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeLocation: window.constants.LCLOCATION,
            lctypeCompany: window.constants.LCCOMPANY,
            attachmentType: window.constants.ATT_DELIVERYNOTE,
            queuedAmount: 0,
            attachmentLijst: [] as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),

            perUnitsId: 0,
            melkId: 0,
            localNaam: '',
            opmerking: '',
            vetKlasseEdit: false,

            merkaantal: 0,
            aangeraakt_teller: 0,

            pakbon: {} as DeliveryNote,
            forcerefresh: false,
            datum: new Date(),
            freeinput: 'false'
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inID: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        //FileUpload,
        SpecialAttachments,
        DeliveryNoteItems
    },
    watch: {},
    computed: {
        formtitle() {
            if (this.pakbon == undefined) return 'Geen selectie';
            return this.pakbon.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: this.$t('prod_pakbon', {
                          ns: 'production'
                      }).toLowerCase()
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.$t('prod_pakbon', {
                          ns: 'production'
                      }).toLowerCase()
                  });
        },
        headertitle(): string {
            const s =
                this.$t('prod_header', {
                    ns: 'production'
                }) +
                this.$t('prod_pakbon', {
                    ns: 'production'
                }).toLowerCase();
            return this.pakbon.isNew ? s : s + ' #:  ' + this.pakbon.id;
        },
        localItem() {
            return this.pakbon;
        },
        showFreeInput(): boolean {
            return this.freeinput == 'true';
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        showAlert(tekst: string): void {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide(): void {
            this.opmerking = '';
            this.$emit('hide');
        },
        setChanged(): void {
            this.pakbon.isChanged = true;
        },
        change(visible): void {
            this.setChanged();
            this.$emit('change', visible);
        },
        async checkFormValidity(): Promise<boolean> {
            const invalidBasis: string = this.pakbon.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            if (this.queuedAmount > 0) {
                const doClose = await (
                    this.$root as VueExtension
                ).showConfirmationBox(
                    this.$t('com_queuedbijlagen', { ns: 'common' })
                );
                if (!doClose) {
                    return false;
                }
            }
            const valid = this.pakbon != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (this.pakbon.isChanged) {
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisProduct();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-deliverynote');
            });
        },
        async saveThisProduct(): Promise<void> {
            const deliveryNoteModel = this.pakbon.SetForSave();
            await loadItem(
                'delivery/addDeliveryNote',
                deliveryNoteModel,
                this.$t
            );
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'components/loadAttachmentInfo',
                {
                    data: {
                        id: this.pakbon.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'components/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await (
                this.$root as VueExtension
            ).showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'components/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    async created() {
        if (this.inID == undefined || this.inID < 0) {
            this.pakbon = new DeliveryNote({});
        } else {
            const item = await loadItem(
                'delivery/loadDeliveryNote',
                {
                    deliverynoteID: this.inID
                },
                this.$t
            );
            this.datum = item.boektijdstip
                ? DateHelper.convertedToDateTimeJS(item.boektijdstip)
                : new Date();
            this.pakbon = item;
            this.freeinput =
                this.pakbon.klanttekst?.length > 0 ? 'true' : 'false';
        }
    }
});
</script>

<style scoped></style>

