<template>
    <b-card no-body :header="$t('com_companies', { ns: 'common' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="8">
                        <b-col sm="3">
                            <label for="input-itemnaam"
                                >{{ $t('com_naam', { ns: 'common' }) }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-itemnaam"
                                :placeholder="$t('com_naam', { ns: 'common' })"
                                v-model="lokaalCompany.naam"
                                :disabled="readonly"
                                @change="setInputBedrijf"
                                :state="checkState"
                            ></b-form-input>
                        </b-col>

                        <b-col sm="3">
                            <label for="input-tel"
                                >{{
                                    $t('com_telefoon', { ns: 'common' })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-input-group class="mb-2">
                                <b-input-group-prepend>
                                    <b-button disabled
                                        ><font-awesome-icon icon="phone"
                                    /></b-button>
                                </b-input-group-prepend>
                                <b-form-input
                                    id="input-tel"
                                    :placeholder="
                                        $t('com_telefoon', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.telefoon"
                                    :disabled="readonly"
                                    @change="setInput"
                                ></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col sm="3">
                            <label for="input-mail"
                                >{{ $t('com_mail', { ns: 'common' }) }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-input-group class="mb-2">
                                <b-input-group-prepend>
                                    <b-button disabled
                                        ><font-awesome-icon icon="envelope"
                                    /></b-button>
                                </b-input-group-prepend>
                                <b-form-input
                                    id="input-mail"
                                    :placeholder="
                                        $t('com_mail', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.mail"
                                    :disabled="readonly"
                                    @change="setInput"
                                ></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-col>
                    <b-col sm="4">
                        <b-col sm="3">
                            <label for="input-afk"
                                >{{ $t('com_afk', { ns: 'common' }) }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-afk"
                                :placeholder="
                                    $t('com_shortname', { ns: 'common' })
                                "
                                v-model="lokaalCompany.naamkort"
                                :disabled="readonly"
                                @change="setInput"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="3">
                            <label for="input-deb"
                                >{{
                                    $t('com_debiteur', { ns: 'common' })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-deb"
                                :placeholder="
                                    $t('com_debiteur', { ns: 'common' })
                                "
                                v-model="lokaalCompany.debiteur"
                                :disabled="readonly"
                                @change="setInput"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="3">
                            <label for="input-cred"
                                >{{
                                    $t('com_crediteur', { ns: 'common' })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-cred"
                                :placeholder="
                                    $t('com_crediteur', { ns: 'common' })
                                "
                                v-model="lokaalCompany.crediteur"
                                :disabled="readonly"
                                @change="setInput"
                            ></b-form-input>
                        </b-col>
                    </b-col>
                </b-row>
                <b-tabs card>
                    <b-tab :title="$t('com_straat', { ns: 'common' })" active>
                        <b-card>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_adres', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_adres', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.adresStraat"
                                    :disabled="readonly"
                                    @change="setInput"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_postcode', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_postcode', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.postcodeStraat"
                                    @change="setInput"
                                    :disabled="readonly"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_plaats', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_plaats', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.plaatsStraat"
                                    @change="setInput"
                                    :disabled="readonly"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_land', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_land', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.land"
                                    @change="setInput"
                                    :disabled="readonly"
                                ></b-form-input>
                            </b-col>
                        </b-card>
                    </b-tab>
                    <b-tab :title="$t('com_post', { ns: 'common' })">
                        <b-card>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_adres', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_adres', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.post1"
                                    :disabled="readonly"
                                    @change="setInput"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_postcode', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_postcode', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.post2"
                                    @change="setInput"
                                    :disabled="readonly"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="3">
                                <label
                                    >{{ $t('com_plaats', { ns: 'common' }) }}:
                                </label>
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    :placeholder="
                                        $t('com_plaats', { ns: 'common' })
                                    "
                                    v-model="lokaalCompany.post3"
                                    @change="setInput"
                                    :disabled="readonly"
                                ></b-form-input>
                            </b-col>
                        </b-card>
                    </b-tab>
                    <b-tab :title="$t('com_opmerking', { ns: 'common' })">
                        <b-card>
                            <b-form-textarea
                                id="opmerking"
                                v-model="lokaalCompany.opmerking"
                                :placeholder="
                                    $t('com_opmerking', { ns: 'common' })
                                "
                                rows="6"
                                max-rows="20"
                                @change="setInput"
                                :disabled="readonly"
                            ></b-form-textarea>
                        </b-card>
                    </b-tab>

                    <special-attachments
                        v-if="false"
                        :itemId="lokaalCompany.id"
                        :attType="attachmentType"
                    />
                </b-tabs>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { Company } from '../../models/MasterData';
import { SpecialAttachments } from '../components';

export default {
    name: 'CardCompany',
    data() {
        return {
            attachmentType: window.constants.ATT_COMPANY,
            lokaalCompany: null,
            flexList: [],
            naamOK: false
        };
    },
    components: {
        SpecialAttachments
    },
    model: {
        prop: 'theCompany',
        event: 'changed'
    },
    props: {
        theCompany: {
            type: Company,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        readonly() {
            return !this.editAllowed;
        },
        show() {
            return this.locatieType;
        },
        checkState() {
            return this.naamOK;
        }
    },
    methods: {
        setInputBedrijf() {
            this.naamOK = this.lokaalCompany
                ? this.lokaalCompany.naam
                    ? this.lokaalCompany.naam.length > 2
                    : false
                : false;
            this.setChanged();
        },
        setInput() {
            this.setChanged();
        },
        setChanged() {
            this.$emit('validComponent', this.theCompany.naam != undefined);
            this.$emit('change', true);
        }
    },
    mounted() {},
    async created() {
        this.lokaalCompany = this.theCompany;
    }
};
</script>

