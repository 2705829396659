import { isNullishCoalesce } from 'typescript';
import store from '../../store';
import { loadItem } from '../../models/DataHelper';

const loadData = async (to, func) => {
    if (typeof to.params.typeBase !== 'undefined') {
        await loadMasterData(to.params.typeBase, func);
        return giveExtraFieldsType(to.params.typeBase, func);
    }
};

const giveExtraFieldsType = (typeBase, func) => {
    let ext = [];
    switch (typeBase) {
        case window.constants.MDATA_VORM:
            ext = [
                {
                    key: 'object.vorm',
                    label: func('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.gewicht',
                    label: func('com_weight', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.diameter',
                    label: func('prod_rond', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case window.constants.MDATA_OST:
            ext = [
                {
                    key: 'object.nummer',
                    label: func('com_nummer', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.maximum',
                    label: func('com_max', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.rest',
                    label: func('com_min', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case window.constants.MDATA_LOCATION: {
            ext = [
                {
                    key: 'object.typeNaam',
                    label: func('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.volume',
                    label: func('prod_volume', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        }
        case window.constants.MDATA_EQUIPMENT: {
            ext = [
                {
                    key: 'object.opmerking',
                    label: func('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        }
        case window.constants.MDATA_COMPANY: {
            ext = [
                {
                    key: 'object.email',
                    label: func('com_mail', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.telefoon',
                    label: func('com_telefoon', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.attachmentinfo',
                    label: func('com_bijlagen', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        }
        case window.constants.MDATA_REPORTGROUP: {
            ext = [
                {
                    key: 'object.typeNaam',
                    label: func('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'object.opmerking',
                    label: func('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        }
        default:
            break;
    }
    return ext;
};

const loadMasterData = async (typeBase, func) => {
    switch (typeBase) {
        case window.constants.MDATA_VORM:
            await loadItem('masterdata/loadShapes', null, func);
            break;
        case window.constants.MDATA_OST:
            await loadItem('masterdata/loadOsts', null, func);
            break;
        case window.constants.MDATA_EQUIPMENT:
            await loadItem('masterdata/loadEquipments', null, func);
            break;
        case window.constants.MDATA_LOCATION: {
            const types = await loadItem(
                'masterdata/loadStockLocationTypes',
                null,
                func
            );
            const payload = {
                data: null,
                filter: null,
                types: types
            };
            await loadItem('masterdata/loadLocations', payload, func);
            break;
        }
        case window.constants.MDATA_COMPANY:
            await loadItem('masterdata/loadCompanies', null, func);
            break;
        case window.constants.MDATA_REPORTGROUP:
            await loadItem('masterdata/loadReportGroups', null, func);
            break;
        case '':
        default:
            break;
    }
    return 'done';
};

export { loadData, loadMasterData };

