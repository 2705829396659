<template>
    <div v-if="toonSelectie" class="form-inline">
        <b-input-group :prepend="giveNaam">
            <b-form-select
                v-model="catID"
                :options="lookupCategories"
                @change="findLabels"
            ></b-form-select>
            <b-form-select
                v-model="labelID"
                :options="geefLijst"
                @change="changed"
            >
            </b-form-select>
        </b-input-group>
        <b-card
            v-if="showCard"
            title="Extra informatie"
            img-src="https://picsum.photos/600/300/?image=25"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
        >
            <b-card-text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
        </b-card>
    </div>
    <div v-else>{{ $t('com_nodata', { ns: 'common' }) }}</div>
</template>
<script>
import { loadList } from '../../models/DataHelper';
export default {
    name: 'LookupLabelCombo',
    data() {
        return {
            errorMessage: '',
            flexList: [],
            lookupCategories: [
                { value: 1, text: '-' },
                { value: 1000, text: 'Certificaten' },
                { value: 1001, text: 'Specificaties' },
                { value: 1002, text: 'Onderhoudsrapporten' }
            ],
            lookupLabels: [
                { value: 1, text: '-', cat: 1 },
                { value: 1000, text: 'Echt', cat: 1000 },
                { value: 1001, text: 'Nep', cat: 1000 },
                { value: 1002, text: 'Tijdelijk', cat: 1001 },
                { value: 1003, text: 'Goedgekeurd', cat: 1001 },
                { value: 1004, text: 'BRM', cat: 1001 },
                { value: 1005, text: 'Motoren', cat: 1002 },
                { value: 1006, text: 'PLC', cat: 1002 },
                { value: 1007, text: 'Vastgoed', cat: 1002 }
            ],
            catID: 1,
            labelID: 1,
            showCard: false
        };
    },
    props: {},
    components: {},
    watch: {},
    computed: {
        toonSelectie() {
            return this.lookupLabels.length > 0;
        },
        giveNaam() {
            return this.withPrepend ? 'Bijlagetype' : '';
        },
        geefLijst() {
            return this.flexList;
        }
    },
    methods: {
        findLabels(cat) {
            this.flexList = this.lookupLabels.filter((x) => x.cat == cat);
            this.showCard = cat >999;
        },
        changed(val) {
            const sel = this.lookupLabels.find((x) => x.value == val);
            const temp = { cat: sel.cat, label: val, text: sel.text };
            this.$emit('change', true);
            this.$emit('input', val);
        }
    },
    mounted() {},
    async created() {
        //todo vul de lijsten
        // const locList = await loadList(
        //     'components/getLookupCategories',
        //     'components/loadLookupCategories',
        //     null,
        //     true,
        //     this.$t
        // );
    }
};
</script>

<style scoped></style>
